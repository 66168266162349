import React, { useEffect, useState } from "react";
import ApiLoader from "../../../common/ApiLoader";
import InnerHeader from "../../../common/Header/InnerHeader";
import Footer from "../../../common/Footer/Footer";
import { Field, Form, Formik } from "formik";
import ProgressBar from "./ProgressBar";
import { t } from "i18next";
import "./style.scss";
import BasicDetails from "./BasicDetails";
import { validationSchema } from "../../../validation/addSpot.validation";
import {
  AddListSpotApiCall,
  getSpotDetailsApiCall,
} from "../../../_services/listSpot/listSpot.service";
import { toast } from "react-toastify";
import SpotPicture from "./SpotPicture";
import SpotPictures from "./SpotPictures";
import OperatingHours from "./OperatingHoursNew";
import Amenities from "./AmenitiesNew";
import { useParams } from "react-router-dom";
import { getAllLookupApiCall } from "../../../_services/lookups/lookups.service";

export default function ListSpotNew() {
  const [loader, setLoader] = React.useState(true);
  const [activeListSpot, setActiveListSpot] = useState(1);
  const [completedStep, setCompletedStep] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [formLoader, setFormLoader] = useState(true);

  const [categoryData, setCategoryData] = useState([]);
  const [styleData, setStyleData] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const [handler, setHandler] = useState({
    quickspot_id: "",
    property_id: "",
  });

  const [data, setData] = useState(null);

  const params = useParams();

  const handleSubmit = (values, actions) => { };

  // useEffect(() => {
  //   if (params.id) {
  //     setIsEdit(true);

  //     getSpotDetailsApiCall(params.id)
  //       .then((response) => {
  //         setLoader(false);
  //         let data = response?.data?.data[0];

  //         setCompletedStep(data.form_last_step);
  //         setActiveListSpot(data.form_last_step);
  //         setHandler({
  //           quickspot_id: data.quickspot_id,
  //           property_id: data.property_id,
  //         });

  //         setLoader(false);
  //         setFormLoader(false);
  //         setData(data);
  //       })
  //       .catch((error) => {
  //         toast.error(JSON.stringify(error.response));
  //         setLoader(false);
  //       });
  //   } else {
  //     setLoader(false);
  //     setFormLoader(false);
  //   }
  // }, []);

  useEffect(() => {
    // get category dropdown data
    getAllLookupApiCall({ qs_lookup_key: "qs_category" })
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          const titleA = a.qs_lookup_details.title.toUpperCase();
          const titleB = b.qs_lookup_details.title.toUpperCase();

          if (titleA < titleB) {
            return -1;
          }
          if (titleA > titleB) {
            return 1;
          }
          return 0;
        });

        setCategoryData(sortedData);
        getAllLookupApiCall({ qs_lookup_key: "qs_style" })
          .then((response) => {
            const sortedData = response.data.sort((a, b) => {
              const titleA = a.qs_lookup_details.title.toUpperCase();
              const titleB = b.qs_lookup_details.title.toUpperCase();

              if (titleA < titleB) {
                return -1;
              }
              if (titleA > titleB) {
                return 1;
              }
              return 0;
            });
            setStyleData(sortedData);
            if (params.id) {
              setIsEdit(true);

              getSpotDetailsApiCall(params.id)
                .then((response) => {
                  setLoader(false);
                  let data = response?.data?.data[0];

                  setCompletedStep(data.form_last_step);
                  setActiveListSpot(data.form_last_step);
                  setHandler({
                    quickspot_id: data.quickspot_id,
                    property_id: data.property_id,
                  });

                  setLoader(false);
                  setFormLoader(false);
                  setData(data);
                })
                .catch((error) => {
                  toast.error(JSON.stringify(error.response));
                  setLoader(false);
                });
            } else {
              setLoader(false);
              setFormLoader(false);
            }
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response));
          });

          getAllLookupApiCall({ qs_lookup_key: "qs_activity" })
          .then((response) => {
            const sortedData = response.data.sort((a, b) => {
              const titleA = a.qs_lookup_details.title.toUpperCase();
              const titleB = b.qs_lookup_details.title.toUpperCase();

              if (titleA < titleB) {
                return -1;
              }
              if (titleA > titleB) {
                return 1;
              }
              return 0;
            });
            setActivityData(sortedData);
          
          })
          .catch((error) => {
            toast.error(JSON.stringify(error.response));
          });
        // setCategoryData(response.data);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response));
      });
    // get style dropdown data
  }, []);

  return (
    <>
      {loader && <ApiLoader />}
      <InnerHeader />
      {formLoader ? (
        ""
      ) : (
        <Formik
          initialValues={{
            basicDetails: {
              spotCategory: data
                ? categoryData.filter((item) => {
                  if (data?.qs_type_ids.includes(item.qs_lookup_id)) {
                    return item;
                  }
                })
                : [],
              spotStyle: data
                ? styleData?.find(
                  (item) => item?.qs_lookup_id === data?.qs_style_id
                )
                : "",

              // spotActivity: data
              // ? activityData?.find(
              //   (item) => item?.qs_lookup_id === data?.qs_activity_id
              // )
              // : "",

              spotActivity: data && Array.isArray(data.qs_activity_id)
                ? activityData.filter((item) => {
                    if (data.qs_activity_id.includes(item.qs_lookup_id)) {
                      return item;
                    }
                  })
                : [],


              spotTitle: data ? data.qs_name : "",
              hourlyRate: data ? data.qs_charges.hourlyRate : "",
              cleaningFee: data ? data.qs_charges.cleaningFee : "",
              discount: data ? data.qs_charges.cleaningFee : "",
              minBookingHr: data ? data.qs_charges.minBookingHr : "",
              maxGuestsAllowed: data ? data.qs_charges.maxGuestsAllowed : "",
              spotDescription: data ? data.qs_charges.spotDescription : "",
            },
            address: {
              country: data ? data.qs_address.country : "",
              streetAddress: data ? data.qs_address.streetAddress : "",
              address2: data ? data.qs_address.streetAddress : "",
              city: data ? data.qs_address.city : "",
              state: data ? data.qs_address.state : "",
              postalCode: data ? data.qs_address.postalCode : "",
              latitude: data ? data.qs_address.latitude : 0,
              longitude: data ? data.qs_address.longitude : 0,
              neigborhood: data ? data.qs_address.neigborhood : "",
              number: data ? data.qs_address.number : "",
            },

            rules: {
              rules: data ? data.qs_rules.rules.rules : "",
            },
            //first step
            //second step
            spotpicture: {
              spotSecurity: false,
              petFriendly: false,
              parkingOptions: false,
              additionalParkingInst: "",
              ageGroup: "",
            },
            availableParking: [],
            images: [],
            //second step
          }}
          onSubmit={handleSubmit}
          validationSchema={() => validationSchema(t)}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            setFieldTouched,
          }) => (
            <Form>
              {/* <pre> {JSON.stringify(values, null, 2)}</pre>
              <pre>{JSON.stringify(errors, null, 2)}</pre>
              <pre>{JSON.stringify(touched, null, 2)}</pre> */}
              <section className="listyourspot">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-8 col-lg-10">
                      <div className="px-4">
                        <h1 className="fw-semibold mb-4 text-default text-md-start text-center">
                          {isEdit ? t("edit_your_spot") : t("list_your_spot")}
                        </h1>
                      </div>
                    </div>
                    <ProgressBar
                      activeListSpot={activeListSpot}
                      setActiveListSpot={setActiveListSpot}
                      completedStep={completedStep}
                      setCompletedStep={setCompletedStep}
                    />
                    <div className="col-xl-8 col-lg-10">
                      <div
                        className="tab-content border-top pt-4 mt-4  px-4"
                        id="myTabContent"
                      >
                        {/* Basic Details */}
                        {activeListSpot === 1 ? (
                          <BasicDetails
                            handleChange={handleChange}
                            values={values}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            handleSubmit={handleSubmit}
                            setFieldTouched={setFieldTouched}
                            setLoader={setLoader}
                            setActiveListSpot={setActiveListSpot}
                            setCompletedStep={setCompletedStep}
                            completedStep={completedStep}
                            setHandler={setHandler}
                            handler={handler}
                          />
                        ) : null}

                        {activeListSpot === 2 ? (
                          <div className="tab-pane active">
                            <SpotPictures
                              handleChange={handleChange}
                              values={values}
                              errors={errors}
                              touched={touched}
                              handler={handler}
                              setFieldValue={setFieldValue}
                              handleSubmit={handleSubmit}
                              setActiveListSpot={setActiveListSpot}
                              setCompletedStep={setCompletedStep}
                              completedStep={completedStep}
                            />
                          </div>
                        ) : null}

                        {activeListSpot === 3 ? (
                          <div className="tab-pane operatehour active">
                            <OperatingHours
                              handleChange={handleChange}
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleSubmit={handleSubmit}
                              handler={handler}
                              setActiveListSpot={setActiveListSpot}
                              setCompletedStep={setCompletedStep}
                            />
                          </div>
                        ) : null}

                        {activeListSpot === 4 ? (
                          <div className="tab-pane amenities active">
                            <Amenities
                              handleChange={handleChange}
                              values={values}
                              errors={errors}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              handleSubmit={handleSubmit}
                              handler={handler}
                              setActiveListSpot={setActiveListSpot}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      )}

      <Footer />
    </>
  );
}
