import { generalHeader, authTokenHeader } from "../auth-header";
import axios from "../Axios";
import { userApi } from "../../api";

// For Adding List Spots for Users
export const AddListSpotApiCall = async (data) => {
  return await axios.post(userApi.addListSpot, data, {
    headers: authTokenHeader(),
  });
};

// Get List Spot details for all stepsa at the time of add spot
export const getListSpotApiCall = async (id) => {
  return await axios.get(`${userApi.getListSpot}/${id}`, {
    headers: authTokenHeader(),
  });
};

// My Listing api
export const getMyListingApiCall = async () => {
  return await axios.get(userApi.myListing, { headers: authTokenHeader() });
};

// Delete Spot
export const deleteSpotApiCall = async (id) => {
  return await axios.delete(`${userApi.myListing}/${id}`, {
    headers: authTokenHeader(),
  });
};

// My Spot Details
export const getSpotDetailsApiCall = async (qs_id) => {
  return await axios.get(userApi.spotDetails + "/" + qs_id, {
    headers: generalHeader(),
  });
};

// Main Spots
export const getMainSpotsApiCall = async () => {
  return await axios.get(userApi.spotDetails, { headers: generalHeader() });
};

// startBooking
export const startBookingSpotApiCall = async (data) => {
  return await axios.post(userApi.startBooking, data, {
    headers: authTokenHeader(),
  });
};

// startBooking
export const saveAndConfirmSpecialOfferBookingSpotApiCall = async (data) => {
  return await axios.post(userApi.saveAndConfirmSpecialOfferBooking, data, {
    headers: authTokenHeader(),
  });
};

// calculated Amount
export const getCalculatedAmountBookingApiCall = async (data) => {
  return await axios.post(userApi.getCalculatedAmountBookingApiCall, data, {
    headers: authTokenHeader(),
  });
};

export const getPaymentLinkOfBookingSpotApiCall = async (data) => {
  return await axios.post(userApi.getPaymentLinkOfBookingSpotApiCall, data, {
    headers: authTokenHeader(),
  });
};

export const getBookingSpotApiCall = async (data) => {
  return await axios.post(userApi.getBookingSpotApiCall, data, {
    headers: authTokenHeader(),
  });
};
