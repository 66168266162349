import { t } from "i18next";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getAllLookupApiCall } from "../../../_services/lookups/lookups.service";
import { toast } from "react-toastify";
import { imagePath } from "../../../constants/imageUrl";
import { AddListSpotApiCall } from "../../../_services/listSpot/listSpot.service";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { createLogs } from "../../../_services/logs/logs.service";
import { authTokenHeader } from "../../../_services/auth-header";

export default function BasicDetails({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  setFieldTouched,
  setLoader,
  setActiveListSpot,
  setCompletedStep,
  completedStep,
  setHandler,
  handler,
  // handleSubmit,
}) {
  const [categoryData, setCategoryData] = useState([]);
  const [styleData, setStyleData] = useState([]);
  const [activityData, setActivityData] = useState([]);

  useEffect(() => {
    // get category dropdown data
    getAllLookupApiCall({ qs_lookup_key: "qs_category" })
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          const titleA = a.qs_lookup_details.title.toUpperCase();
          const titleB = b.qs_lookup_details.title.toUpperCase();

          if (titleA < titleB) {
            return -1;
          }
          if (titleA > titleB) {
            return 1;
          }
          return 0;
        });

        setCategoryData(sortedData);
        // setCategoryData(response.data);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response));
      });
    // get style dropdown data
    getAllLookupApiCall({ qs_lookup_key: "qs_style" })
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          const titleA = a.qs_lookup_details.title.toUpperCase();
          const titleB = b.qs_lookup_details.title.toUpperCase();

          if (titleA < titleB) {
            return -1;
          }
          if (titleA > titleB) {
            return 1;
          }
          return 0;
        });

        // setCategoryData(sortedData);
        setStyleData(sortedData);
      })
      .catch((error) => {
        toast.error(JSON.stringify(error.response));
      });

      // get Activity dropdown data
    getAllLookupApiCall({ qs_lookup_key: "qs_activity" })
    .then((response) => {
      const sortedData = response.data.sort((a, b) => {
        const titleA = a.qs_lookup_details.title.toUpperCase();
        const titleB = b.qs_lookup_details.title.toUpperCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      setActivityData(sortedData);
    })
    .catch((error) => {
      toast.error(JSON.stringify(error.response));
    });

  }, []);

  const handleNext = async () => {
    setFieldTouched("basicDetails.spotCategory", true);
    setFieldTouched("basicDetails.spotStyle", true);
    setFieldTouched("basicDetails.spotActivity", true);
    setFieldTouched("basicDetails.spotTitle", true);
    setFieldTouched("basicDetails.hourlyRate", true);
    setFieldTouched("basicDetails.cleaningFee", true);
    setFieldTouched("basicDetails.discount", true);
    setFieldTouched("basicDetails.minBookingHr", true);
    setFieldTouched("basicDetails.maxGuestsAllowed", true);
    setFieldTouched("basicDetails.spotDescription", true);
    setFieldTouched("address.country", true);
    setFieldTouched("address.postalCode", true);
    setFieldTouched("address.streetAddress", true);
    setFieldTouched("address.number", true);
    setFieldTouched("address.neigborhood", true);
    setFieldTouched("address.city", true);
    setFieldTouched("address.state", true);
    setFieldTouched("rules.rules", true);

    if (
      errors.basicDetails?.spotCategory ||
      errors.basicDetails?.spotStyle ||
      errors.basicDetails?.spotActivity ||
      errors.basicDetails?.spotTitle ||
      errors.basicDetails?.hourlyRate ||
      errors.basicDetails?.cleaningFee ||
      errors.basicDetails?.discount ||
      errors.basicDetails?.minBookingHr ||
      errors.basicDetails?.maxGuestsAllowed ||
      errors.basicDetails?.spotDescription ||
      errors.address?.country ||
      errors.address?.postalCode ||
      errors.address?.streetAddress ||
      errors.address?.number ||
      errors.address?.neigborhood ||
      errors.address?.city ||
      errors.address?.state ||
      errors.rules?.rules
    ) {
      return;
    }

    const qsLookupIds = values.basicDetails.spotCategory.map(
      (item) => item.qs_lookup_id
    );
    const spotStyle = values.basicDetails.spotStyle.qs_lookup_id;
    // const spotActivity = values?.basicDetails?.spotActivity?.qs_lookup_id;
    const spotActivity = values.basicDetails.spotActivity.map(
      (item) => item.qs_lookup_id
    );

    setLoader(true);

    let data = {
      formSerial: 1,
      basicDetails: {
        spotCategory: qsLookupIds,
        spotStyle: spotStyle,
        spotActivity: spotActivity,
        spotTitle: values?.basicDetails?.spotTitle,
        hourlyRate: values?.basicDetails?.hourlyRate,
        cleaningFee: values?.basicDetails?.cleaningFee,
        discount: values?.basicDetails?.discount,
        minBookingHr: values?.basicDetails?.minBookingHr,
        maxGuestsAllowed: values?.basicDetails?.maxGuestsAllowed,
        spotDescription: values?.basicDetails?.spotDescription,
      },
      address: {
        country: values?.address?.country,
        streetAddress: values?.address?.streetAddress,
        address2: values?.address?.address2,
        city: values?.address?.city,
        state: values?.address?.state,
        postalCode: values?.address?.postalCode,
        latitude: 0,
        longitude: 0,
        neigborhood: values?.address?.neigborhood,
        number: values?.address?.number,
      },
      rules: {
        rules: values?.rules?.rules,
      },
    };

    if (handler.quickspot_id !== "" && handler.property_id !== "") {
      setHandler({
        quickspot_id: handler.quickspot_id,
        property_id: handler.property_id,
      });

      data.handler = handler;
    }
    const address = data.address;

    const new_address = ` ${address.streetAddress}, ${address.number}, ${address.address2}, ${address.neigborhood}, ${address.city}, ${address.postalCode}, ${address.state}, Brasil`;
    try {
      const results = await geocodeByAddress(new_address);
      const area = await getLatLng(results[0]);
      data.address.latitude = area.lat;
      data.address.longitude = area.lng;
    } catch (e) {
      toast.error("Por Favor insira um endereço válido.");
      return;
    }

    AddListSpotApiCall(data)
      .then((response) => {
        if (response.status === 200) {
          const resHandler = {
            quickspot_id: response?.data?.data?.spot_id?.quickspot_id,
            property_id: response?.data?.data?.property_id?.property_id,
          };
          // setFieldValue("handler.quickspot_id", resHandler.quickspot_id);
          // setFieldValue("handler.property_id", resHandler.property_id);

          setHandler(resHandler);

          setActiveListSpot(2);
          if (completedStep === 1) {
            setCompletedStep(2);
          } else {
            setCompletedStep(completedStep);
          }
          setLoader(false);

          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        createLogs({
          url: "/frontend/edit-list-spot?step=1",
          payload: JSON.stringify({
            error,
            values,
            headers: authTokenHeader(),
          }),
        });
        setLoader(false);
        let errorMessage = "Error:";
        error.response.data.errors.map((error) => {
          const field = Object.keys(error)[0];
          errorMessage = `${errorMessage} The ${field} is ${error[field]}.`; // Customize the message as needed
          return errorMessage;
        });

        toast.error(errorMessage);
      });
  };

  return (
    <div className="tab-pane active">
      <h3 className="fw-semibold mb-4">{t("basic_details")}</h3>
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating">
            <Select
              autoFocus
              options={categoryData}
              value={values?.basicDetails?.spotCategory}
              isMulti={true}
              getOptionLabel={(category) => category?.qs_lookup_details?.title}
              getOptionValue={(category) => category?.qs_lookup_id}
              onChange={(value) => {
                setFieldValue("basicDetails.spotCategory", value);
              }}
              onBlur={() => setFieldTouched("basicDetails.spotCategory", true)}
              placeholder={t("select_category")}
            />
            <select
              className="form-select  h-50 d-none"
              value={values?.basicDetails?.spotCategory}
              onChange={(e) =>
                setFieldValue("basicDetails.spotCategory", e.target.value)
              }
            >
              <option value="selected">{t("select_category")}</option>
              {categoryData?.map((category, index) => (
                <option key={index} value={category?.qs_lookup_id}>
                  {category?.qs_lookup_details?.title}
                </option>
              ))}
            </select>
            <label> {t("spot_category")} *</label>
            {touched?.basicDetails?.spotCategory && (
              <p class="fs-11 text-danger pt-1">
                {errors?.basicDetails?.spotCategory}
              </p>
            )}

            <small className="text-muted">
              Selecione todas as categorias ou tipos de espaços que você
              disponibilizará no anúncio do seu spot.
            </small>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating">
            <Select
              options={styleData}
              // defaultValue={categoryData[0]}
              value={values?.basicDetails?.spotStyle}
              //styles={customStyles}
              getOptionLabel={(category) => category?.qs_lookup_details?.title}
              getOptionValue={(category) => category?.qs_lookup_id}
              onChange={(value) => {
                setFieldValue("basicDetails.spotStyle", value);
              }}
              placeholder={t("select_style")}
            />
            <select
              className="form-select  h-50 d-none"
              value={values.basicDetails.spotStyle}
              onChange={(e) =>
                setFieldValue("basicDetails.spotStyle", e.target.value)
              }
            >
              <option value="selected">{t("select_style")}</option>
              {styleData?.map((style, index) => (
                <option key={index} value={style?.qs_lookup_id}>
                  {style?.qs_lookup_details?.title}
                </option>
              ))}
            </select>
            <label>{t("spot_style")} *</label>
            {touched?.basicDetails?.spotStyle && (
              <p class="fs-11 text-danger pt-1">
                {errors?.basicDetails?.spotStyle}
              </p>
            )}

            <small className="text-muted">
              Selecione o estilo principal que melhor se aplica aos espaços.
              Caso uma categoria tenha um estilo muito diferente, recomendamos
              criar um novo anúncio exclusivo para essa categoria.
            </small>
          </div>
        </div>

        {/* ----------------------------------------------------------- */}
        <div className="col-md-12">
          <div className="form-floating">
            <Select
              options={activityData}
              value={values?.basicDetails?.spotActivity}
              isMulti={true}
              getOptionLabel={(category) => category?.qs_lookup_details?.title}
              getOptionValue={(category) => category?.qs_lookup_id}
              onChange={(value) => {
                setFieldValue("basicDetails.spotActivity", value);
              }}
              placeholder={t("select_type_of_activity")}
            />
            <select
              className="form-select  h-50 d-none"
              value={values.basicDetails.spotActivity}
              onChange={(e) =>
                setFieldValue("basicDetails.spotActivity", e.target.value)
              }
            >
              <option value="selected">{t("select_type_of_activity")}</option>
              {activityData?.map((activity, index) => (
                <option key={index} value={activity?.qs_lookup_id}>
                  {activity?.qs_lookup_details?.title}
                </option>
              ))}
            </select>
            <label>{t("spot_activity")} *</label>
            {touched?.basicDetails?.spotActivity && (
              <p class="fs-11 text-danger pt-1">
                {errors?.basicDetails?.spotActivity}
              </p>
            )}

            <small className="text-muted">
              {/* add some text like above */}
            </small>
          </div>
        </div>
        {/* ----------------------------------------------------------- */}

        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control rounded"
              placeholder={t("spot_title")}
              value={values?.basicDetails?.spotTitle}
              onChange={(e) =>
                setFieldValue("basicDetails.spotTitle", e.target.value)
              }
            />
            <label>{t("spot_title")} *</label>

            {touched?.basicDetails?.spotTitle && (
              <p className="fs-11 text-danger pt-1">
                {errors?.basicDetails?.spotTitle}
              </p>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3 position-relative">
            <div className="position-absolute start-0 top-0 mt-10 text-default w-36 text-center pe-none">
              {imagePath.ratedollar}
            </div>
            <input
              type="number"
              className="form-control rounded ps-36"
              placeholder={t("hourly_rate")}
              value={values?.basicDetails?.hourlyRate}
              onChange={(e) =>
                setFieldValue("basicDetails.hourlyRate", e.target.value)
              }
              onKeyDown={(e) => {
                if (
                  (e.keyCode < 48 || e.keyCode > 57) &&
                  e.keyCode !== 8 &&
                  e.keyCode !== 9 &&
                  (e.keyCode < 96 || e.keyCode > 105)
                ) {
                  e.preventDefault();
                }
              }}
            />
            <label className="ms-4">{t("hourly_rate")} *</label>

            {touched?.basicDetails?.hourlyRate && (
              <p className="fs-11 text-danger pt-1">
                {errors?.basicDetails?.hourlyRate}
              </p>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating mb-3 position-relative">
            <div className="position-absolute start-0 top-0 mt-10 text-default w-36 text-center pe-none">
              {imagePath.ratedollar}
            </div>
            <input
              type="number"
              className="form-control ps-36"
              placeholder={t("cleaning_fee")}
              value={values.basicDetails.cleaningFee}
              onChange={(e) =>
                setFieldValue("basicDetails.cleaningFee", e.target.value)
              }
              onKeyDown={(e) => {
                if (
                  (e.keyCode < 48 || e.keyCode > 57) &&
                  e.keyCode !== 8 &&
                  e.keyCode !== 9 &&
                  (e.keyCode < 96 || e.keyCode > 105)
                ) {
                  e.preventDefault();
                }
              }}
            />
            <label className="ms-4">{t("cleaning_fee")}</label>
            {touched?.basicDetails?.cleaningFee && (
              <p className="fs-11 text-danger pt-1">
                {errors?.basicDetails?.cleaningFee}
              </p>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="number"
              className="form-control"
              placeholder={t(
                "discount_percent_will_apply_on_hours_5_bookings_only"
              )}
              value={values?.basicDetails?.discount}
              onChange={(e) =>
                setFieldValue("basicDetails.discount", e.target.value)
              }
              onKeyDown={(e) => {
                if (
                  (e.keyCode < 48 || e.keyCode > 57) &&
                  e.keyCode !== 8 &&
                  e.keyCode !== 9 &&
                  (e.keyCode < 96 || e.keyCode > 105)
                ) {
                  e.preventDefault();
                }
              }}
            />
            <label>
              {" "}
              {t("discount_percent_will_apply_on_hours_5_bookings_only")}
            </label>

            {touched?.basicDetails?.discount && (
              <p className="fs-11 text-danger pt-1">
                {errors?.basicDetails?.discount}
              </p>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="number"
              className="form-control"
              placeholder={t("minimum_booking_hours")}
              value={values?.basicDetails?.minBookingHr}
              onChange={(e) =>
                setFieldValue("basicDetails.minBookingHr", e.target.value)
              }
              onKeyDown={(e) => {
                if (
                  (e.keyCode < 48 || e.keyCode > 57) &&
                  e.keyCode !== 8 &&
                  e.keyCode !== 9 &&
                  (e.keyCode < 96 || e.keyCode > 105)
                ) {
                  e.preventDefault();
                }
              }}
            />
            <label>{t("minimum_booking_hours")}</label>
            {touched?.basicDetails?.minBookingHr && (
              <p className="fs-11 text-danger pt-1">
                {errors?.basicDetails?.minBookingHr}
              </p>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="number"
              className="form-control"
              placeholder={t("maximum_guests_allowed")}
              value={values?.basicDetails?.maxGuestsAllowed}
              onChange={(e) =>
                setFieldValue("basicDetails.maxGuestsAllowed", e.target.value)
              }
              onKeyDown={(e) => {
                if (
                  (e.keyCode < 48 || e.keyCode > 57) &&
                  e.keyCode !== 8 &&
                  e.keyCode !== 9 &&
                  (e.keyCode < 96 || e.keyCode > 105)
                ) {
                  e.preventDefault();
                }
              }}
            />
            <label>{t("maximum_guests_allowed")} *</label>
            {touched?.basicDetails?.maxGuestsAllowed && (
              <p className="fs-11 text-danger pt-1">
                {errors?.basicDetails?.maxGuestsAllowed}
              </p>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating mb-3">
            <textarea
              rows="10"
              className="form-control"
              placeholder={t("spot_description")}
              value={values?.basicDetails?.spotDescription}
              onChange={(e) =>
                setFieldValue("basicDetails.spotDescription", e.target.value)
              }
            ></textarea>
            <label>{t("spot_description")} *</label>
            {touched?.basicDetails?.spotDescription && (
              <p className="fs-11 text-danger pt-1">
                {errors?.basicDetails?.spotDescription}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h3 className="fw-semibold mb-0 mt-3">{t("address")}</h3>
          <p className="fw-normal fs-15 text-default-400 mb-3 pb-1">
            {t("it_will_only_shared_with_guests_after_they_book_the_space")}
          </p>
        </div>
      </div>
      <div className="row">
        {/* country */}
        <div className="col-md-6">
          <div className="form-floating">
            <select
              className="form-select h-50"
              value={values?.address?.country}
              onChange={(e) => setFieldValue("address.country", e.target.value)}
            >
              <option value="">{t("please_select_country")}</option>
              <option value="Brasil">Brasil</option>
            </select>
            <label> {t("country")} *</label>
            {touched?.address?.country && (
              <p className="fs-11 text-danger pt-1">
                {errors?.address?.country}
              </p>
            )}
          </div>
        </div>

        {/* number */}
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="Number"
              maxLength={250}
              value={values?.address?.postalCode}
              onChange={(e) =>
                setFieldValue("address.postalCode", e.target.value)
              }
            />

            <label>{t("zip_code")} *</label>

            {touched?.address?.postalCode && (
              <p className="fs-11 text-danger pt-1">
                {errors?.address?.postalCode}
              </p>
            )}
          </div>
        </div>
      </div>
      {/* City */}
      <div className="row">
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="Address 2"
              maxLength={250}
              value={values?.address?.streetAddress}
              onChange={(e) =>
                setFieldValue("address.streetAddress", e.target.value)
              }
            />

            <label>{t("street")} *</label>
            {touched?.address?.streetAddress && (
              <p className="fs-11 text-danger pt-1">
                {errors?.address?.streetAddress}
              </p>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="Number"
              maxLength={250}
              value={values?.address?.number}
              onChange={(e) => setFieldValue("address.number", e.target.value)}
            />
            <label>{t("number")} *</label>

            {touched?.address?.number && (
              <p className="fs-11 text-danger pt-1">
                {errors?.address?.number}
              </p>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="Address 2"
              maxLength={250}
              value={values?.address?.address2}
              onChange={(e) =>
                setFieldValue("address.address2", e.target.value)
              }
            />
            <label>{t("address_two")}</label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="Neigborhood"
              maxLength={250}
              value={values?.address?.neigborhood}
              onChange={(e) =>
                setFieldValue("address.neigborhood", e.target.value)
              }
            />
            <label> {t("Neigborhood")} *</label>

            {touched?.address?.neigborhood && (
              <p className="fs-11 text-danger pt-1">
                {errors?.address?.neigborhood}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        {/* Neigborhood */}

        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="City"
              maxLength={250}
              value={values?.address?.city}
              onChange={(e) => setFieldValue("address.city", e.target.value)}
            />
            <label>{t("city")} *</label>

            {touched?.address?.city && (
              <p className="fs-11 text-danger pt-1">{errors?.address?.city}</p>
            )}
          </div>
        </div>
        {/* State */}
        <div className="col-md-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="State"
              maxLength={250}
              value={values?.address?.state}
              onChange={(e) => setFieldValue("address.state", e.target.value)}
            />
            <label> {t("state")} *</label>

            {touched?.address?.state && (
              <p className="fs-11 text-danger pt-1">{errors?.address?.state}</p>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h3 className="fw-semibold mb-0 mt-3">
            {t("rules_and_instructions")}
          </h3>
          <p className="fw-normal fs-15 text-default-400 mb-3 pb-1">
            {t("include_all_does_and_donts_of_the_house_rules")}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-floating mb-3">
            <textarea
              name=""
              rows="10"
              minLength={250}
              className="form-control pt-sm-5"
              placeholder="Eg. no smoking on the building, outside catering is allowed etc. *"
              value={values.rules.rules}
              onChange={(e) => {
                setFieldValue("rules.rules", e.target.value);
              }}
            ></textarea>
            <label className="lh-base">
              {t(
                "eg_no_smoking_on_the_building_outside_catering_is_allowed_etc"
              )}
              *
            </label>

            {touched?.rules?.rules && (
              <p className="fs-11 text-danger pt-1">{errors?.rules?.rules}</p>
            )}
          </div>
        </div>
      </div>
      <div className="row justify-content-between ">
        <div className="col-4"></div>
        <div className="col-4 text-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleNext}
          >
            {t("next")}
          </button>
        </div>
      </div>
    </div>
  );
}
