import * as Yup from "yup";

const validationSchema = (t) =>
  Yup.object().shape({
    handler: Yup.object().shape({
      quickspot_id: Yup.string(),
      property_id: Yup.string(),
    }),
    // first step
    basicDetails: Yup.object().shape({
      spotCategory: Yup.array()
        .of(Yup.object().required(t("please_select_spot_category")))
        .min(1, t("please_select_spot_category")),
      spotStyle: Yup.object().required(t("please_select_spot_style")),
      // spotActivity: Yup.object().required(t("please_select_spot_activity")),
      spotActivity: Yup.array()
        .of(Yup.object().required(t("please_select_spot_activity")))
        .min(1, t("please_select_spot_activity")),
      spotTitle: Yup.string()
        .required(t("please_enter_spot_title."))
        .min(3, t("please_enter_minimum_3_characters_in_title."))
        .max(50, t("please_enter_maximum_50_characters_in_title.")),
      hourlyRate: Yup.string()
        .required(t("please_enter_valid_hours."))
        .matches(
          /^(?!0)(?:\d{1,3})$/,
          t("please_enter_valid_hours_between_1_-_999.")
        ),
      cleaningFee: Yup.string()
        .required(t("please_enter_cleaning_Fee."))
        .matches(
          /^(?!0)(?:\d{1,3})$/,
          t("please_enter_valid_cleaning_fee_between_1_-_999.")
        ),
      discount: Yup.string()
        .nullable()
        .matches(
          /^(?!0)(?:\d{1,2})$/,
          t("please_enter_valid_discount_in_percentage_between_1_-_99.")
        ),

      minBookingHr: Yup.number().min(
        1,
        t("please_enter_valid_minimum_booking_hours.")
      ),
      maxGuestsAllowed: Yup.string()
        .required(t("please_enter_maximum_guests_allowed."))
        .matches(
          /^(?:[1-9]|[1-9][0-9])$/,
          t("maximum_guests_allowed_validation")
        ),
      spotDescription: Yup.string()
        .required(t("please_enter_spot_description."))
        .min(50, t("please_enter_minimum_50_characters_in_description."))
        .max(1000, t("please_enter_minimum_1000_characters_in_description.")),
    }),
    address: Yup.object().shape({
      country: Yup.string().required(t("")),
      streetAddress: Yup.string()
        .required(t("please_enter_street"))
        .min(1, t(t("minimum_1_characters")))
        .max(50, t(t("maximum_50_characters"))),
      address2: Yup.string()
        .nullable()
        .min(1, t("minimum_1_characters"))
        .max(50, t("maximum_50_characters")),
      city: Yup.string()
        .required(t("please_enter_city."))
        .min(1, t("minimum_1_characters"))
        .max(50, t("maximum_50_characters")),
      state: Yup.string()
        .required(t("please_enter_state"))
        .min(1, t("minimum_1_characters"))
        .max(50, t("maximum_50_characters")),
      postalCode: Yup.string()
        .min(8, t("please_enter_valid_postal_code"))
        .max(11, t("please_enter_valid_postal_code"))
        .required(t("please_enter_postal_code")),
      latitude: Yup.number().required(t("Latitude is required")),
      longitude: Yup.number().required(t("Longitude is required")),
      neigborhood: Yup.string()
        .required(t("neighborhood_is_required"))
        .min(1, t("minimum_1_characters"))
        .max(50, t("maximum_50_characters")),
      number: Yup.string()
        .required(t("please_enter_number"))
        .min(1, t("minimum_1_characters"))
        .max(50, t("maximum_50_characters")),
    }),
    rules: Yup.object().shape({
      rules: Yup.string()
        .min(5, t("please_enter_minimum_5_characters_in_rules."))
        .max(4000, t("please_enter_maximum_4000_characters_in_rules."))
        .required(t("please_enter_rules_&_instructions.")),
    }),
    //first step

    //second step
    spotpicture: Yup.object().shape({
      spotSecurity: Yup.boolean().required(),
      petFriendly: Yup.boolean().required(),
      parkingOptions: Yup.boolean().required(),
      additionalParkingInst: Yup.string()
        .min(1, t("minimum_1_characters"))
        .max(50, t("maximum_50_characters")),
      ageGroup: Yup.string().required(),
    }),
    availableParking: Yup.array().of(Yup.string()).required(),
    images: Yup.array().of(Yup.string()).required(),
    //second step
  });

export { validationSchema };
