// const apibasePathDev = 'http://3.131.116.215:3001' ;
const apibasePathDev = process.env.REACT_APP_API_URL;

export const userApi = {
  signUp: `${apibasePathDev}/users/signup`,
  completeProfile: `${apibasePathDev}/users/complete_your_profile`,
  getCompleteProfile: `${apibasePathDev}/users/complete_user_info`,
  updateProfile: `${apibasePathDev}/users/update_profile`,
  verificationCode: `${apibasePathDev}/users/resend_verification_code`,
  verifyUserApiCall: `${apibasePathDev}/users/verify_user`,
  login: `${apibasePathDev}/users/login`,
  forgotPassword: `${apibasePathDev}/users/forgot_password`,
  resetPassword: `${apibasePathDev}/users/update_password`,
  changePassword: `${apibasePathDev}/users/change_password`,
  addListSpot: `${apibasePathDev}/myspots/addSpot`,
  getListSpot: `${apibasePathDev}/myspots/view`,
  images: `${apibasePathDev}/images`,
  myListing: `${apibasePathDev}/myspots`,
  spotDetails: `${apibasePathDev}/viewspot`,
  addOrRemoveMyWishlist: `${apibasePathDev}/myspots/manageWishlist`,
  getMyWishlist: `${apibasePathDev}/myspots/fetch_spot_wishlist`,
  search: `${apibasePathDev}/getspots`,
  globalSetting: `${apibasePathDev}/v1/general-settings`,
  blogs: `${apibasePathDev}/v1/blogs`,
  userBlogs: `${apibasePathDev}/v1/blogs/user/blogs`,
  getBlockOutDates: `${apibasePathDev}/blockout`,
  startBooking: `${apibasePathDev}/booking/checkavailable`,
  saveAndConfirmSpecialOfferBooking: `${apibasePathDev}/booking/save-and-confirm-special-offer-booking`,
  getCalculatedAmountBookingApiCall: `${apibasePathDev}/booking/get-calculated-amount-booking-api-call`,
  getPaymentLinkOfBookingSpotApiCall: `${apibasePathDev}/booking/get-payment-link-of-booking-spot-api-call`,
  getBookingSpotApiCall: `${apibasePathDev}/booking/get-booking-spot-api-call`,
  getlookupDataAsc: `${apibasePathDev}/getlist`,
  referralSendEmail: `${apibasePathDev}/referral/sendmail`,
  cmsPages: `${apibasePathDev}/v1/cms-pages`,
  helpTopics: `${apibasePathDev}/v1/help-topics`,
  faqs: `${apibasePathDev}/v1/faqs`,
  chatUsers: `${apibasePathDev}/chat`,
  oneToOneChat: `${apibasePathDev}/chat/getOne2One`,
  chatInitiate: `${apibasePathDev}/chat/startnode`,
  fileUpload: `${apibasePathDev}/images`,
  unreadMessageCount: `${apibasePathDev}/chat/unreadcount`,
  contactUs: `${apibasePathDev}/v1/contact-us`,
  socialLogin: `${apibasePathDev}/users/saveSocialLogin`,
  bookingStatusList: `${apibasePathDev}/booking/statuslist`,
  bookingList: `${apibasePathDev}/booking`,
  bookingDates: `${apibasePathDev}/booking/bookingDates`,
  spotFeedbackSubmit: `${apibasePathDev}/v1/spot-feedback`,
  spotFeedbackBySpotId: `${apibasePathDev}/v1/spot-feedback/spot`,
  saveBooking: `${apibasePathDev}/booking/savebooking`,
  saveSpecialBookingSomeData: `${apibasePathDev}/booking/save-special-booking-some-data`,
  bookingDetails: `${apibasePathDev}/booking/detail`,
  cancelBooking: `${apibasePathDev}/booking/cancel`,
  checkCancellation: `${apibasePathDev}/booking/checkcancellation`,
  paymentCardAdd: `${apibasePathDev}/v1/payment-cards`,
  payoutRecipent: `${apibasePathDev}/recipent`,
  recipient: `${apibasePathDev}/v1/recipient`,
  billingAddress: `${apibasePathDev}/v1/billing-addresses`,
  booking: `${apibasePathDev}/v1/booking`,
  payment: `${apibasePathDev}/v1/payment`,
  portfolio: `${apibasePathDev}/v1/portfolio`,

  spotAddExtraHoursSubmit: `${apibasePathDev}/booking/add-extra-hours`,
  spotAddExtraHoursPaymentSubmit: `${apibasePathDev}/booking/add-extra-hours-payment`,
  paymentForExtraHours: `${apibasePathDev}/v1/payment/payment-for-extra-hours`,
  
  getBookingDetailsForSpecialOfferPayment: `${apibasePathDev}/booking/get-booking-details-for-special-offer-payment`,
  specialOfferpayment: `${apibasePathDev}/v1/payment/payment-for-special-offer`,
};

export const adminApi = {
  login: `${apibasePathDev}/admin/login`,
  forgotPassword: `${apibasePathDev}/admin/forgot_password`,
  updatePassword: `${apibasePathDev}/admin/update_password`,
  changePassword: `${apibasePathDev}/admin/change_password`,
  lookups: `${apibasePathDev}/v1/lookups`,
  mlookups: `${apibasePathDev}/v1/lookups/mlookups`,
  logout: `${apibasePathDev}/v1/authentication/logout`,
  users: `${apibasePathDev}/v1/users`,
  uploadFile: `${apibasePathDev}/v1/upload`,
  globalSetting: `${apibasePathDev}/v1/general-settings`,
  profile: `${apibasePathDev}/v1/profile`,
};
