import React, { Suspense, lazy, useEffect, useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { userRoutes, adminRoutes } from "./Routes";
import Toaster from "./common/Toaster";
import ContentLoader from "./common/ContentLoader";
// import $ from 'jquery';
import { PrivateRoute } from "./PrivateRoute";
import { getCmsPagesApiCall } from "./_services/cms/cms.services";
import NotFound from "./component/Pages/common/NotFound";
import ScrollToTop from "./component/scrollToTop";
const CmsPage = lazy(() => import("./component/Pages/ContentPages/cmsPages"));

function App() {
  const [loading, setLoading] = useState(true);
  const [cmsPages, setCmsPages] = useState([]);
  useEffect(() => {
    getCmsPagesApiCall().then((response) => {
      setLoading(false);
      setCmsPages(response.data);
    });
  }, []);
  return (
    <div className="App d-flex flex-column minHeight100">
      {!loading && (
        <Suspense fallback={<ContentLoader />}>
          <Router>
            <ScrollToTop />

            <Routes>
              {cmsPages.map((cmsPage) => (
                <Route
                  path={`${process.env.REACT_APP_BASE_URL}/${cmsPage.page_url}`}
                  element={<CmsPage {...cmsPage} />}
                  exact
                  key={cmsPage.page_id}
                />
              ))}

              <Route
                path={userRoutes.audiovisualProductions.path}
                element={userRoutes.audiovisualProductions.component}
                exact
              />

              <Route
                path={userRoutes.home.path}
                element={userRoutes.home.component}
                exact
              />

              <Route
                path={userRoutes.signup.path}
                element={userRoutes.signup.component}
              />
              <Route
                path={userRoutes.completeProfile.path}
                element={userRoutes.completeProfile.component}
              />
              <Route
                path={userRoutes.login.path}
                element={userRoutes.login.component}
              />
              <Route
                path={userRoutes.forgotPassword.path}
                element={userRoutes.forgotPassword.component}
              />
              <Route
                path={userRoutes.createPassword.path}
                element={userRoutes.createPassword.component}
              />
              <Route
                path={userRoutes.resetPassword.path}
                element={userRoutes.resetPassword.component}
              />

              <Route
                path={userRoutes.completeProfile.path}
                element={userRoutes.completeProfile.component}
              />
              {/* <Route
                path={userRoutes.termsConditions.path}
                element={userRoutes.termsConditions.component}
              /> */}

              <Route
                path={userRoutes.searchResult.path}
                element={userRoutes.searchResult.component}
              />
              <Route
                path={userRoutes.publicList.path}
                element={userRoutes.publicList.component}
              />
              <Route
                path={userRoutes.allCategories.path}
                element={userRoutes.allCategories.component}
              />
              <Route
                path={userRoutes.payments.path}
                element={userRoutes.payments.component}
              />
              <Route
                path={userRoutes.extraPayments.path}
                element={userRoutes.extraPayments.component}
              />
              <Route
                path={userRoutes.specialOfferPayment.path}
                element={userRoutes.specialOfferPayment.component}
              />
              <Route
                path={userRoutes.specialOfferBooking.path}
                element={userRoutes.specialOfferBooking.component}
              />
              <Route
                path={userRoutes.allCities.path}
                element={userRoutes.allCities.component}
              />
              <Route
                path={`${userRoutes.blogs.path}`}
                element={userRoutes.blogs.component}
              />
              <Route
                path={`${userRoutes.blogDetails.path}/:id`}
                element={userRoutes.blogDetails.component}
              />
              {/* <Route
                path={userRoutes.privacyPolicy.path}
                element={userRoutes.privacyPolicy.component}
              /> */}
              {/* <Route path="/completeprofile" element={<CompletProfile />} /> */}

              {/* <Route path="/searchresult" element={<SearchResult />} /> */}

              {/* <Route path="/mybookings" element={<MyBookings />} /> */}
              {/* <Route path="/mylistings" element={<MyListings />} /> */}
              <Route
                path={`${userRoutes.listSpotDetail.path}/:id`}
                element={userRoutes.listSpotDetail.component}
              />
              <Route
                path={`${userRoutes.bookingDetails.path}/:id`}
                element={userRoutes.bookingDetails.component}
              />
              <Route
                path={`${userRoutes.listSpotDetail.path}/:id`}
                element={userRoutes.listSpotDetail.component}
              />

              <Route
                path={`${userRoutes.portfolio.path}`}
                element={userRoutes.portfolio.component}
              />
              <Route element={<PrivateRoute />}>
                {/* spot add delete edit listing */}
                <Route
                  path={userRoutes.transactions.path}
                  element={userRoutes.transactions.component}
                />
                <Route
                  path={userRoutes.myListing.path}
                  element={userRoutes.myListing.component}
                />
                <Route
                  path={userRoutes.listSpot.path}
                  element={userRoutes.listSpot.component}
                />
                <Route
                  path={userRoutes.addSpotThanku.path}
                  element={userRoutes.addSpotThanku.component}
                />
                <Route
                  path={`${userRoutes.editListSpot.path}/:id`}
                  element={userRoutes.editListSpot.component}
                />
                <Route
                  path={userRoutes.updateProfile.path}
                  element={userRoutes.updateProfile.component}
                />
                <Route
                  path={userRoutes.myWishlist.path}
                  element={userRoutes.myWishlist.component}
                />
                <Route
                  path={userRoutes.referral.path}
                  element={userRoutes.referral.component}
                />
                <Route
                  path={`${userRoutes.spotBooking.path}/:id`}
                  element={userRoutes.spotBooking.component}
                />

                <Route
                  path={userRoutes.inbox.path}
                  element={userRoutes.inbox.component}
                />
                <Route
                  path={userRoutes.myBookings.path}
                  element={userRoutes.myBookings.component}
                />
              </Route>

              <Route
                path={userRoutes.helpPage.path}
                element={userRoutes.helpPage.component}
              />

              {/* <Route path="/blog" element={<Blog />} /> */}
              {/* <Route path="/blogdetail" element={<BlogDetail />} /> */}
              <Route
                path={userRoutes.contactUs.path}
                element={userRoutes.contactUs.component}
              />
              {/* <Route path="/updateprofile" element={<UpdateProfile />} />  */}
              <Route
                path={userRoutes.contactUs.path}
                element={userRoutes.contactUs.component}
              />

              <Route path="*" exact={true} element={<NotFound />} />
            </Routes>
          </Router>
        </Suspense>
      )}

      <Toaster />
    </div>
  );
}

export default App;
